import React from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

import { CardProvider } from "acciondigital-basic-theme/src/components/CartContext"
import AuthProvider from "acciondigital-basic-theme/src/context/AuthContext"

import infoSitio from "../utils/infoSitio"
import { LosMuralesSiteProvider } from "../context/LosMuralesSiteContext"

const { netlifySiteId: sitio } = infoSitio
console.log("🚀 ~ file: rootElement.js:11 ~ infoSitio:", infoSitio)

const queryClient = new QueryClient()


export const RootElement = ({ children }) => (
  // <AuthProvider sitio={sitio} getUserProperties={getUserProperties}>
  //     <QueryClientProvider client={queryClient}>
  //   <SiteContextProvider>
  //       <CardProvider sitio={sitio} saveToFirebase={true} vigenciaDiasDefault={-1}>
  //         {children}
  //         {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  //       </CardProvider>
  //   </SiteContextProvider>
  //   </QueryClientProvider>
  // </AuthProvider>
  <AuthProvider sitio={sitio}>
    <QueryClientProvider client={queryClient}>
    <CardProvider sitio={sitio} saveToFirebase={true} vigenciaDiasDefault={-1}>
      <LosMuralesSiteProvider>
        {children}
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </LosMuralesSiteProvider>
    </CardProvider>
    </QueryClientProvider>
  </AuthProvider>
)

  //   <AuthProvider sitio={sitio} getUserProperties={getUserProperties}>
  //     <SiteContextProvider>
  //       <CardProvider sitio={sitio} saveToFirebase={true} vigenciaDiasDefault={-1}>
  //         {element}
  //       </CardProvider>
  //     <SiteContextProvider>
  //   </AuthProvider>

export default RootElement
