exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-admon-productos-js": () => import("./../../../src/pages/admon-productos.js" /* webpackChunkName: "component---src-pages-admon-productos-js" */),
  "component---src-pages-admon-tiendas-js": () => import("./../../../src/pages/admon-tiendas.js" /* webpackChunkName: "component---src-pages-admon-tiendas-js" */),
  "component---src-pages-admon-usuarios-js": () => import("./../../../src/pages/admon-usuarios.js" /* webpackChunkName: "component---src-pages-admon-usuarios-js" */),
  "component---src-pages-alta-afiliado-js": () => import("./../../../src/pages/alta-afiliado.js" /* webpackChunkName: "component---src-pages-alta-afiliado-js" */),
  "component---src-pages-alta-negocio-js": () => import("./../../../src/pages/alta-negocio.js" /* webpackChunkName: "component---src-pages-alta-negocio-js" */),
  "component---src-pages-alta-publicacion-js": () => import("./../../../src/pages/alta-publicacion.js" /* webpackChunkName: "component---src-pages-alta-publicacion-js" */),
  "component---src-pages-alta-restaurante-js": () => import("./../../../src/pages/alta-restaurante.js" /* webpackChunkName: "component---src-pages-alta-restaurante-js" */),
  "component---src-pages-aviso-privacidad-js": () => import("./../../../src/pages/aviso-privacidad.js" /* webpackChunkName: "component---src-pages-aviso-privacidad-js" */),
  "component---src-pages-buscar-js": () => import("./../../../src/pages/buscar.js" /* webpackChunkName: "component---src-pages-buscar-js" */),
  "component---src-pages-canasta-compra-js": () => import("./../../../src/pages/canasta-compra.js" /* webpackChunkName: "component---src-pages-canasta-compra-js" */),
  "component---src-pages-ciudad-slug-cat-categoria-slug-js": () => import("./../../../src/pages/[ciudad_slug]/cat/[categoria_slug].js" /* webpackChunkName: "component---src-pages-ciudad-slug-cat-categoria-slug-js" */),
  "component---src-pages-ciudad-slug-cat-categoria-slug-negos-[pag]-js": () => import("./../../../src/pages/[ciudad_slug]/cat/[categoria_slug]/negos/[pag].js" /* webpackChunkName: "component---src-pages-ciudad-slug-cat-categoria-slug-negos-[pag]-js" */),
  "component---src-pages-ciudad-slug-cat-categoria-slug-negos-js": () => import("./../../../src/pages/[ciudad_slug]/cat/[categoria_slug]/negos.js" /* webpackChunkName: "component---src-pages-ciudad-slug-cat-categoria-slug-negos-js" */),
  "component---src-pages-ciudad-slug-cat-categoria-slug-novedades-[pag]-js": () => import("./../../../src/pages/[ciudad_slug]/cat/[categoria_slug]/novedades/[pag].js" /* webpackChunkName: "component---src-pages-ciudad-slug-cat-categoria-slug-novedades-[pag]-js" */),
  "component---src-pages-ciudad-slug-cat-categoria-slug-novedades-js": () => import("./../../../src/pages/[ciudad_slug]/cat/[categoria_slug]/novedades.js" /* webpackChunkName: "component---src-pages-ciudad-slug-cat-categoria-slug-novedades-js" */),
  "component---src-pages-ciudad-slug-nego-afiliado-slug-admin-js": () => import("./../../../src/pages/[ciudad_slug]/nego/[afiliado_slug]/admin.js" /* webpackChunkName: "component---src-pages-ciudad-slug-nego-afiliado-slug-admin-js" */),
  "component---src-pages-ciudad-slug-nego-afiliado-slug-admin-menu-js": () => import("./../../../src/pages/[ciudad_slug]/nego/[afiliado_slug]/admin-menu.js" /* webpackChunkName: "component---src-pages-ciudad-slug-nego-afiliado-slug-admin-menu-js" */),
  "component---src-pages-ciudad-slug-nego-afiliado-slug-admin-novedades-js": () => import("./../../../src/pages/[ciudad_slug]/nego/[afiliado_slug]/admin-novedades.js" /* webpackChunkName: "component---src-pages-ciudad-slug-nego-afiliado-slug-admin-novedades-js" */),
  "component---src-pages-ciudad-slug-nego-afiliado-slug-alta-publicacion-js": () => import("./../../../src/pages/[ciudad_slug]/nego/[afiliado_slug]/alta-publicacion.js" /* webpackChunkName: "component---src-pages-ciudad-slug-nego-afiliado-slug-alta-publicacion-js" */),
  "component---src-pages-ciudad-slug-nego-afiliado-slug-canasta-compra-js": () => import("./../../../src/pages/[ciudad_slug]/nego/[afiliado_slug]/canasta-compra.js" /* webpackChunkName: "component---src-pages-ciudad-slug-nego-afiliado-slug-canasta-compra-js" */),
  "component---src-pages-ciudad-slug-nego-afiliado-slug-cotizacion-pedido-uuid-js": () => import("./../../../src/pages/[ciudad_slug]/nego/[afiliado_slug]/cotizacion/[pedido_uuid].js" /* webpackChunkName: "component---src-pages-ciudad-slug-nego-afiliado-slug-cotizacion-pedido-uuid-js" */),
  "component---src-pages-ciudad-slug-nego-afiliado-slug-cotizador-js": () => import("./../../../src/pages/[ciudad_slug]/nego/[afiliado_slug]/cotizador.js" /* webpackChunkName: "component---src-pages-ciudad-slug-nego-afiliado-slug-cotizador-js" */),
  "component---src-pages-ciudad-slug-nego-afiliado-slug-menu-js": () => import("./../../../src/pages/[ciudad_slug]/nego/[afiliado_slug]/menu.js" /* webpackChunkName: "component---src-pages-ciudad-slug-nego-afiliado-slug-menu-js" */),
  "component---src-pages-ciudad-slug-nego-afiliado-slug-tienda-js": () => import("./../../../src/pages/[ciudad_slug]/nego/[afiliado_slug]/tienda.js" /* webpackChunkName: "component---src-pages-ciudad-slug-nego-afiliado-slug-tienda-js" */),
  "component---src-pages-ciudad-slug-nego-nego-slug-js": () => import("./../../../src/pages/[ciudad_slug]/nego/[nego_slug].js" /* webpackChunkName: "component---src-pages-ciudad-slug-nego-nego-slug-js" */),
  "component---src-pages-ciudad-slug-negos-[pag]-js": () => import("./../../../src/pages/[ciudad_slug]/negos/[pag].js" /* webpackChunkName: "component---src-pages-ciudad-slug-negos-[pag]-js" */),
  "component---src-pages-ciudad-slug-novedades-[pag]-js": () => import("./../../../src/pages/[ciudad_slug]/novedades/[pag].js" /* webpackChunkName: "component---src-pages-ciudad-slug-novedades-[pag]-js" */),
  "component---src-pages-ciudad-slug-post-post-slug-js": () => import("./../../../src/pages/[ciudad_slug]/post/[post_slug].js" /* webpackChunkName: "component---src-pages-ciudad-slug-post-post-slug-js" */),
  "component---src-pages-ciudad-slug-test-ciudad-js": () => import("./../../../src/pages/[ciudad_slug]/test-ciudad.js" /* webpackChunkName: "component---src-pages-ciudad-slug-test-ciudad-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-cotizacion-pedido-uuid-js": () => import("./../../../src/pages/cotizacion/[pedido_uuid].js" /* webpackChunkName: "component---src-pages-cotizacion-pedido-uuid-js" */),
  "component---src-pages-dev-admon-tiendas-test-more-js": () => import("./../../../src/pages/dev/admon-tiendas-test-more.js" /* webpackChunkName: "component---src-pages-dev-admon-tiendas-test-more-js" */),
  "component---src-pages-dev-admon-tiendas-test-paginacion-js": () => import("./../../../src/pages/dev/admon-tiendas-test-paginacion.js" /* webpackChunkName: "component---src-pages-dev-admon-tiendas-test-paginacion-js" */),
  "component---src-pages-dev-firebase-2-js": () => import("./../../../src/pages/dev/firebase2.js" /* webpackChunkName: "component---src-pages-dev-firebase-2-js" */),
  "component---src-pages-dev-firebase-js": () => import("./../../../src/pages/dev/firebase.js" /* webpackChunkName: "component---src-pages-dev-firebase-js" */),
  "component---src-pages-dev-firebase-test-js": () => import("./../../../src/pages/dev/firebase-test.js" /* webpackChunkName: "component---src-pages-dev-firebase-test-js" */),
  "component---src-pages-dev-test-menu-js": () => import("./../../../src/pages/dev/test-menu.js" /* webpackChunkName: "component---src-pages-dev-test-menu-js" */),
  "component---src-pages-dev-test-pages-js": () => import("./../../../src/pages/dev/test-pages.js" /* webpackChunkName: "component---src-pages-dev-test-pages-js" */),
  "component---src-pages-form-guia-ventas-online-alta-restaurante-js": () => import("./../../../src/pages/form/guia-ventas-online/alta-restaurante.js" /* webpackChunkName: "component---src-pages-form-guia-ventas-online-alta-restaurante-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-juchitan-nego-fantastico-bazar-del-istmo-cotizador-js": () => import("./../../../src/pages/juchitan/nego/fantastico-bazar-del-istmo/cotizador.js" /* webpackChunkName: "component---src-pages-juchitan-nego-fantastico-bazar-del-istmo-cotizador-js" */),
  "component---src-pages-logos-js": () => import("./../../../src/pages/logos.js" /* webpackChunkName: "component---src-pages-logos-js" */),
  "component---src-pages-losmurales-negos-js": () => import("./../../../src/pages/losmurales-negos.js" /* webpackChunkName: "component---src-pages-losmurales-negos-js" */),
  "component---src-pages-losmurales-novedades-js": () => import("./../../../src/pages/losmurales-novedades.js" /* webpackChunkName: "component---src-pages-losmurales-novedades-js" */),
  "component---src-pages-mi-cuenta-js": () => import("./../../../src/pages/mi-cuenta.js" /* webpackChunkName: "component---src-pages-mi-cuenta-js" */),
  "component---src-pages-registro-js": () => import("./../../../src/pages/registro.js" /* webpackChunkName: "component---src-pages-registro-js" */),
  "component---src-pages-registro-negocio-js": () => import("./../../../src/pages/registro-negocio.js" /* webpackChunkName: "component---src-pages-registro-negocio-js" */),
  "component---src-templates-afiliado-detalle-js": () => import("./../../../src/templates/AfiliadoDetalle.js" /* webpackChunkName: "component---src-templates-afiliado-detalle-js" */),
  "component---src-templates-afiliado-menu-js": () => import("./../../../src/templates/AfiliadoMenu.js" /* webpackChunkName: "component---src-templates-afiliado-menu-js" */),
  "component---src-templates-afiliado-tienda-js": () => import("./../../../src/templates/AfiliadoTienda.js" /* webpackChunkName: "component---src-templates-afiliado-tienda-js" */),
  "component---src-templates-afiliados-categoria-ciudad-js": () => import("./../../../src/templates/AfiliadosCategoriaCiudad.js" /* webpackChunkName: "component---src-templates-afiliados-categoria-ciudad-js" */),
  "component---src-templates-afiliados-ciudad-js": () => import("./../../../src/templates/AfiliadosCiudad.js" /* webpackChunkName: "component---src-templates-afiliados-ciudad-js" */),
  "component---src-templates-alta-afiliado-template-js": () => import("./../../../src/templates/AltaAfiliadoTemplate.js" /* webpackChunkName: "component---src-templates-alta-afiliado-template-js" */),
  "component---src-templates-alta-publicacion-template-js": () => import("./../../../src/templates/AltaPublicacionTemplate.js" /* webpackChunkName: "component---src-templates-alta-publicacion-template-js" */),
  "component---src-templates-catalogo-productos-ciudad-js": () => import("./../../../src/templates/CatalogoProductosCiudad.js" /* webpackChunkName: "component---src-templates-catalogo-productos-ciudad-js" */),
  "component---src-templates-categoria-ciudad-js": () => import("./../../../src/templates/CategoriaCiudad.js" /* webpackChunkName: "component---src-templates-categoria-ciudad-js" */),
  "component---src-templates-ciudad-old-js": () => import("./../../../src/templates/CiudadOld.js" /* webpackChunkName: "component---src-templates-ciudad-old-js" */),
  "component---src-templates-contenidos-template-js": () => import("./../../../src/templates/ContenidosTemplate.js" /* webpackChunkName: "component---src-templates-contenidos-template-js" */),
  "component---src-templates-noticias-paginas-js": () => import("./../../../src/templates/NoticiasPaginas.js" /* webpackChunkName: "component---src-templates-noticias-paginas-js" */),
  "component---src-templates-novedades-categoria-ciudad-js": () => import("./../../../src/templates/NovedadesCategoriaCiudad.js" /* webpackChunkName: "component---src-templates-novedades-categoria-ciudad-js" */),
  "component---src-templates-pagina-ciudad-comida-js": () => import("./../../../src/templates/PaginaCiudadComida.js" /* webpackChunkName: "component---src-templates-pagina-ciudad-comida-js" */),
  "component---src-templates-pagina-ciudad-js": () => import("./../../../src/templates/PaginaCiudad.js" /* webpackChunkName: "component---src-templates-pagina-ciudad-js" */),
  "component---src-templates-producto-detalle-js": () => import("./../../../src/templates/ProductoDetalle.js" /* webpackChunkName: "component---src-templates-producto-detalle-js" */),
  "component---src-templates-publicacion-noticia-js": () => import("./../../../src/templates/PublicacionNoticia.js" /* webpackChunkName: "component---src-templates-publicacion-noticia-js" */),
  "component---src-templates-publicacion-recurso-js": () => import("./../../../src/templates/PublicacionRecurso.js" /* webpackChunkName: "component---src-templates-publicacion-recurso-js" */),
  "component---src-templates-recursos-paginas-js": () => import("./../../../src/templates/RecursosPaginas.js" /* webpackChunkName: "component---src-templates-recursos-paginas-js" */)
}

